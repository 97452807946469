<template>
  <div class="pended-notes d-appoinments">
    <v-row class="d-appoinments_header" justify="space-between" no-gutters>
      <v-col class="d-appoinments_header_title">
        <span class="heading-2"> Pended Notes </span>
      </v-col>
    </v-row>
    <div class="pt-10 scheduled">
      <Tabs :arrows="true" :tabs="tabs" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Tabs from "@/components/shared/Tabs";
import { useEncountersStore } from "@/pinia-store/encounters";
import { linkToPractitionerPendedNotes, linkToPractitionerPendedNotesPending } from "@/router/linkGenerator";

export default {
  name: "PractitionerPendedNotesView",
  components: { Tabs },
  methods: {
    ...mapActions(useEncountersStore, ["getPendedPendingEncounters", "getPendedEncounters"]),
  },
  computed: {
    ...mapState(useEncountersStore, ["pendedPendingEncounters", "pendedEncounters"]),
    tabs() {
      return [
        {
          value: "Draft",
          text: "Draft",
          to: linkToPractitionerPendedNotes(),
          chip: this.pendedEncounters.length,
        },
        {
          value: "Pending",
          text: "Pending",
          to: linkToPractitionerPendedNotesPending(),
          chip: this.pendedPendingEncounters.length,
        },
      ];
    },
  },

  async mounted() {
    await this.getPendedEncounters();
    await this.getPendedPendingEncounters();
  },
};
</script>

<style lang="scss" scoped>
.pended-notes {
  .tabs-router {
    display: flex;
    align-items: center;

    .separator {
      height: 18px;
      border-right: 1px solid #e7e8f2;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      color: #91909c;
      text-align: center;
      text-decoration: none;
      background: white;
      width: 100%;
      padding: 24px 12px;
      border-top: 2px solid #e7e8f2;

      &.active {
        color: #2fcf6f;
        border-top: 2px solid #2fcf6f;
      }
    }
  }
}
</style>
